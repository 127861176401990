/* eslint-disable @next/next/no-img-element */
'use client';

import { Button, Typography } from '@mui/material';

import Illustrations from '@/components/Illustrations';

interface ErrorPageProps {
  errorCode?: number;
  errorMessage: string;
  title: string;
  showBackButton?: boolean;
}

export default function ErrorPage(props: ErrorPageProps) {
  const { errorCode, errorMessage, title, showBackButton = true } = props;
  const lightImg = '/images/pages/misc-mask-light.png';
  const miscBackground = lightImg;

  return (
    <div className='flex items-center justify-center min-bs-[100dvh] relative p-6 overflow-x-hidden'>
      <div className='flex items-center flex-col text-center gap-10'>
        <div className='flex flex-col gap-2 is-[90vw] sm:is-[unset]'>
          {!!errorCode && (
            <Typography className='font-medium text-8xl' color='text.primary'>
              {errorCode}
            </Typography>
          )}
          <Typography variant='h4'>{`${title} ⚠️`}</Typography>
          <Typography>{errorMessage}</Typography>
        </div>
        <img
          alt='error-illustration'
          src='/images/illustrations/characters/9.png'
          className='object-cover bs-[400px] md:bs-[450px] lg:bs-[500px]'
        />
        {showBackButton && (
          <Button onClick={() => history.back()} variant='contained'>
            Back
          </Button>
        )}
      </div>
      <Illustrations maskImg={{ src: miscBackground }} />
    </div>
  );
}
