'use client';

import { useEffect } from 'react';

import ErrorPage from '@/views/errorPage';

const Generic_Error_Title = 'Something went wrong';
const Generic_Error_Message =
  'An error occurred. If the issue persist contact our support.';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <ErrorPage
      errorCode={0}
      errorMessage={Generic_Error_Message}
      title={Generic_Error_Title}
    />
  );
}
